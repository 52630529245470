<template>
  <div class="page-view home">
    <div class="container">
      <div class="">
        <div class="item sm:w-1/2 lg:w-1/3">
          <h1 class="page-title"><v-icon name="bi-activity" />Dashboard</h1>
          <h3 class="subtitle">Premi sul tasto arancione sottostante per avviare la ricerca utenti.</h3>
          <div class="form-wrapper">
            <Form @submit="searchLogs">
              <!-- <label for="phone">
                Ricerca per <b>numero</b>
              </label>
              <Field
                type="tel"
                name="phone"
                rules="phone"
                placeholder="+39"
              />
              <ErrorMessage name="phone" />
              <label for="desc">
                Ricerca per <b>descrizione</b>
              </label>
              <Field
                type="text"
                name="desc"
                placeholder="Descrizione"
              />
              <ErrorMessage name="desc" /> -->
              <button type="submit" class="btn submit">
                Avvia ricerca
              </button>
            </Form>
          </div>
          <section class="stats" v-if="stats">
            <ul>
              <li v-for="(stat, index) in stats" :key="index">
                {{stat.label}}&nbsp;:&nbsp;
                <b :class="{'changing' : this.animatedList.includes(stat.label)}">
                  {{stat.val}}
                </b>
              </li>
            </ul>
          </section>
        </div>
        <div class="item large">
          <div v-if="validated">
            <h5 v-if="!phoneVal">—Registro completo:</h5>
            <h5 v-else>—Risultati per il numero: {{ phoneVal }}</h5>
            <div v-if="loading" class="loading animate-pulse">CARICAMENTO ...</div>
            <h6 class="error" v-else-if="history === false">
              Errore durante la ricerca.
            </h6>
            <div v-else-if="history">
              <div class="table-wrapper">
                <table-lite :is-static-mode="false" :is-loading="table.isLoading" :columns="table.columns"
                  :rows="table.rows" :total="table.totalRecordCount" :sortable="table.sortable"
                  :messages="table.messages" :is-hide-paging="true" @do-search="doSearch"
                  @is-finished="tableLoadingFinish" @get-now-page="currentPage" />
                <div class="custom-pagination">
                  <button v-if="tablePaging && tablePaging >= 1" class="prev" @click="getPrevList">
                    « Prev
                  </button>
                  <button v-if="history && history.length && history.length === this.pageLimit" class="next"
                    @click="getNextList">
                    Next »
                  </button>
                </div>
              </div>
              <hr class="mt-10" />
              <div class="selector-wrapper">
                <p class="inline-block mr-2 text-sm">Seleziona il periodo del quale desideri esportare gli utenti:</p>
                <select v-model="campaign">
                  <option value="1" selected>
                    Periodo 1
                  </option>
                  <option value="2">
                    Periodo 2
                  </option>
                  <option value="C">
                    Periodo Supercashback
                  </option>
                  <option value="4">
                    Periodo 4
                  </option>
                </select>
              </div>
              <Form @submit="sendMail" class="download-form lg:w-1/2 lg:inline-block relative">
                <button type="submit" class="download">
                  <span>+</span> Esporta il registro: inserisci un indirizzo mail
                </button>
                <Field type="mail" name="mail" rules="req|email" />
                <ErrorMessage name="mail" />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img v-if="!history" class="bg-image" src="@/assets/img/dashboard.svg" />
    <a class="logout" to="/login" href="#" @click.prevent="logout">
      ‹‹ Logout
      <b>{{stateMail}}</b>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'

import TableLite from 'vue3-table-lite'
import { reactive } from 'vue'

export default {
  name: 'Home',
  components: {
    Field,
    Form,
    ErrorMessage,
    TableLite
  },
  data () {
    return {
      interval: null,
      loading: false,
      history: null,
      phoneVal: null,
      descVal: null,
      mailVal: null,
      stats: null,
      categories: [],
      filteredCats: [],
      testQuery: this.$route.query.test,
      validated: false,
      validated2: false,
      animatedList: [],
      tablePaging: 0,
      pageLimit: 20,
      campaign: 1
    }
  },
  computed: {
    ...mapGetters([
      'stateMail'
    ])
  },
  methods: {
    getPrevList () {
      this.tablePaging = this.tablePaging - 1
      this.doSearch(this.tablePaging * this.pageLimit, this.pageLimit)
    },
    getNextList () {
      this.tablePaging = this.tablePaging + 1
      this.doSearch(this.tablePaging * this.pageLimit, this.pageLimit)
    },
    tableLoadingFinish (elements) {
      this.table.isLoading = false
    },
    currentPage (page) {
      // this.tablePaging = page
    },
    async doSearch (offset, limit) {
      this.table.isLoading = true
      const data = { phone: this.phoneVal, desc: this.descVal, offset: offset, limit: limit, categories: this.filteredCats }
      await this.$store.dispatch('getUsers', data)
        .then((response) => {
          // refresh table rows
          console.log('RSP', response)
          this.history = response
          this.table.rows = this.history
          this.table.totalRecordCount = response.length
        })
      this.table.isLoading = false
    },
    async filterByCat (cat) {
      this.table.isLoading = true
      if (this.filteredCats.includes(cat)) {
        this.filteredCats = this.filteredCats.filter((val, index) => {
          return val !== cat
        })
      } else {
        this.filteredCats.push(cat)
      }
      const data = { phone: this.phoneVal, desc: this.descVal, offset: 0, limit: this.pageLimit, categories: this.filteredCats }
      await this.$store.dispatch('getUsers', data)
        .then((response) => {
          // refresh table rows
          console.log('RSP', response)
          this.history = response
          this.table.rows = this.history
          this.table.totalRecordCount = response.length
        })
      this.table.isLoading = false
    },
    async getStats (animated) {
      this.animatedList = []
      if (!animated) this.loading = true
      await this.$store.dispatch('getStats')
        .then((rsp) => {
          const temp = []
          for (let i = 0; i < Object.keys(rsp).length; i++) {
            const lbl = Object.keys(rsp)[i]
            const val = Object.values(rsp)[i]
            const translated = {
              label: null,
              val
            }
            switch (lbl) {
              case 'users_count':
                translated.label = 'Totale utenti'
                temp.push(translated)
                break
              case 'users_count_active':
                translated.label = 'Totale utenti con IBAN'
                temp.push(translated)
                break
              default:
                translated.label = lbl
            }
          }
          if (animated) {
            for (let i = 0; i < temp.length; i++) {
              if (!this.stats || !this.stats[i] || this.stats[i].val !== temp[i].val) {
                this.animatedList.push(temp[i].label)
              }
            }
            // console.log('TO ANIMATE', this.animatedList)
          }
          this.stats = temp
          setTimeout(() => {
            this.loading = false
          }, 3000)
        })
        .catch((e) => {
          console.error('GOING TO LOGOUT', e)
          this.$router.push('/login')
        })
      if (!this.stats) this.$router.push('/login')
    },
    async logout () {
      await this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
    },
    async searchLogs (values) {
      this.validated = true
      this.phoneVal = values.phone
      this.descVal = values.desc
      this.loading = true
      await this.doSearch(0, this.pageLimit)
      for (let i = 0; i < this.table.rows.length; i++) {
        if (!this.categories.includes(this.table.rows[i].category)) {
          this.categories.push(this.table.rows[i].category)
        }
      }

      setTimeout(() => {
        this.loading = false
      }, 1500)
    },
    async sendMail (values) {
      this.validated2 = true
      this.mailVal = values.mail
      const start = process.env.VUE_APP_PRECAMPAIGN_START.split('+')[0]
      const end = process.env.VUE_APP_SITE_END.split('+')[0]

      let kind = process.env.VUE_APP_CAMPAIGN_1_2024
      switch (this.campaign) {
        case '2':
          kind = process.env.VUE_APP_CAMPAIGN_2_2024
          break
        case 'C':
          kind = process.env.VUE_APP_CAMPAIGN_SUPERCASHBACK
          break
        case '4':
          kind = process.env.VUE_APP_CAMPAIGN_4_2024
          break
      }
      const payload = {
        start,
        end,
        recipients: [
          this.mailVal
        ],
        item_kind: kind
      }
      await this.$store.dispatch('sendMail', payload)
        .then((rsp) => {
          let msg, error
          if (rsp) {
            msg = 'Email inviata correttamente all\'indirizzo: ' + this.mailVal
            error = false
          } else {
            msg = 'ERRORE: problema tecnico. Riprovare più tardi.'
            error = true
          }
          this.emitter.emit('fireAlert', { msg, error })
          // window.alert(msg)
        })
    }
  },
  setup () {
    const table = reactive({
      isLoading: false,
      // isReSearch: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          width: '3%',
          // sortable: true,
          isKey: true
        },
        {
          label: 'E-mail',
          field: 'email'
          // sortable: true
        },
        {
          label: 'Nome',
          field: 'first_name'
          // sortable: true
        },
        {
          label: 'Cognome',
          field: 'last_name'
          // sortable: true
        },
        {
          label: 'Cod. Fisc.',
          field: 'fiscal_code'
        },
        {
          label: 'IBAN',
          field: 'iban'
        },
        {
          label: 'Filiale',
          field: 'filiale'
        },
        {
          label: 'Data completamento',
          field: 'birth_date',
          width: '20%'
          // sortable: true
        },
        {
          label: 'Data creazione',
          field: 'created_at',
          width: '20%'
          // sortable: true
        }
      ],
      rows: [],
      totalRecordCount: 0,
      // sortable: {
      //   order: 'id',
      //   sort: 'desc'
      // },
      messages: {
        pagingInfo: 'Elementi {0}-{1} di {2}',
        pageSizeChangeLabel: 'Righe per vista:',
        gotoPageLabel: 'Vai alla pagina:',
        noDataAvailable: 'Nessun dato disponibile.'
      }
    })
    return {
      table
    }
  },
  watch: {
    $route (to, from) {
      clearInterval(this.interval)
    }
  },
  async mounted () {
    // console.log('---STORE', this.$store.state)
    await this.getStats(false)
    this.interval = setInterval(() => {
      // console.log('check...')
      this.getStats(true)
        .then(async () => {
          if (this.animatedList.length) await this.searchLogs()
        })
      setTimeout(() => {
        this.animatedList = []
      }, 15000)
    }, 60000) // (60sec)
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/forms.scss";
  @import "@/assets/scss/tables.scss";
  @import "@/assets/scss/views/home.scss";
</style>
